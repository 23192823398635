<template>
  <div>
    <v-form ref="form">
      <v-card elevation="2" outlined class="mx-auto" max-width="800">
        <v-card-title>Resultado escenario</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="uuid"
            label="ID Escenario"
            prepend-icon="mdi-text"
            :rules="[rules.required, rules.validid]"
            autocomplete="off"
            :disabled="submited"
          ></v-text-field>
          <v-file-input
            v-model="file"
            show-size
            small-chips
            truncate-length="20"
            label="Archivo"
            accept=".csv"
            :rules="[rules.required]"
            :disabled="submited"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              color="indigo darken-4"
              dark
              @click="submit"
              :disabled="submited"
            >
              Crear
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      type="success"
      class="mt-6 mx-auto"
      max-width="300"
      elevation="5"
      transition="scroll-y-transition"
      :value="alert"
    >
      Procesando resultado
    </v-alert>
  </div>
</template>
<script>
import { firebase } from "@/firebase";

export default {
  data() {
    return {
      submited: false,
      uuid: "",
      file: null,
      alert: false,
      errors: [],
      rules: {
        required: (value) => !!value || "Requerido.",
        validid: (value) =>
          !!this.$store.state.scenarios.find(({ uuid }) => uuid === value) ||
          "ID Invalido.",
      },
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.submited = true;
      await firebase
        .storage()
        .ref()
        .child(`validar_gis/salida_${this.uuid}.csv`)
        .put(this.file);
      await firebase
        .firestore()
        .collection("scenarios")
        .doc(this.uuid)
        .update({
          state: 4,
          state_desc: "PROCESANDO",
        });
      this.alert = true;
      setTimeout(
        () =>
          this.$router.push({
            name: "scenarios",
          }),
        2000
      );
    },
  },
};
</script>
